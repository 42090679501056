import * as React from "react"
import { Helmet } from "react-helmet"

import Header from "../components/header"
import Theme from "../components/theme"
import Footer from "../components/footer";

const IndexPage = () => {
  return (
    <Theme>
      <Helmet>
        <title>Angela Hollstein - Coaching und Supervision</title>
      </Helmet>
      {/* Hero */}
      <div className="w-full min-h-screen p-16">

        <Header smallText />

        <h2 className="text-5xl text-gray-900 leading-tight px-6 font-bold mt-6">Vielen Dank für Ihre Anfrage</h2>
        
        <p className="pt-6">
          Ich habe Ihre Nachricht erhalten und werde mich schnellstmöglich bei Ihnen melden.
        </p>

      </div>

      <Footer />
    </Theme>
  )
}

export default IndexPage
